import React from "react";
import GovernStakingDetailItem from "../../../../Govern/GovernStakingDetailItem";
import { LoadingModal } from "../../../../../components/Loading";
import Connector from "../../../../../context/WalletConnector/Connector";
import useSGConstants from "../../../../../web3/hooks/ShogunGovern/ReadOnly/useSGConstants";
import { TOKENS } from "../../../../../web3/constants";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import SigmaButton from "../../../../../components/Animation/SigmaButton";
import { useSigmaAlert } from "../../../../../components/SigmaAlert";
import GovCaseVoteModal from "../Modal/GovCaseVoteModal";

const GovListRowDetails = ({ governanceItem }) => {
  let { description, proposalId } = governanceItem;

  const { address, isWalletConnected, connectWallet } =
    Connector.useContainer();
  const {
    isCallSuccessProposalState,
    isLoadingProposalState,

    isProposalStateActive,
    fetchProposalState,

    isLoadingProposal,
    numberedStartBlock,
    isPositiveStartBlock,

    numberedEndBlock,
    isPositiveEndBlock,

    result,

    totalVotesBN,
    displayTotalVotes,
    displayForVotes,
    displayForVotesPercent,
    displayAgainstVotes,
    displayAgainstVotesPercent,
    fetchProposal,

    totalVXSHOSupply,
    totalVXSHOSupplyBN,
    isLoadingTotalVXSHOSupply,
    isCallSuccessTotalVXSHOSupply,
    isValidTotalVXSHOSupply,
    isPositiveTotalVXSHOSupply,
    numberedTotalVXSHOSupply,

    displayTotalVXSHOSupply,
    displayNumberFormatTotalVXSHOSupply,
    fetchTotalVXSHOSupply,

    availableVotes,
    availableVotesBN,
    isLoadingAvailableVotes,
    isCallSuccessAvailableVotes,
    isValidAvailableVotes,
    isPositiveAvailableVotes,
    numberedAvailableVotes,

    displayAvailableVotes,
    displayNumberFormatAvailableVotes,
    fetchAvailableVotes
  } = useSGConstants(address);

  /** Life Cycle */
  useSigmaDidMount(
    () => {
      fetchProposalState(proposalId);
      fetchProposal(proposalId);
      fetchTotalVXSHOSupply(proposalId);
      fetchAvailableVotes(proposalId);
    },
    [],
    false
  );

  /** Events */

  const onSuccessTransactions = (type) => {
    if (!isWalletConnected) return;
    switch (type) {
      case "castVote":
        fetchProposal(proposalId);
        fetchTotalVXSHOSupply(proposalId);
        fetchAvailableVotes(proposalId);
        break;

      default:
        break;
    }
  };

  const onClickVote = async () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidVote) return;
    openVotePopup();
  };

  const handleCancelPopup = (type) => {
    switch (type) {
      case "castVote":
        closeVotePopup();
        break;
      default:
        break;
    }
  };

  const displayVXSHOSupplyPercent = React.useMemo(() => {
    const VXSIGSupplyPercent = totalVotesBN.times(100).div(totalVXSHOSupplyBN);
    if (VXSIGSupplyPercent.isNaN()) return 0;
    return VXSIGSupplyPercent.decimalPlaces(2);
  }, [totalVotesBN, totalVXSHOSupplyBN]);

  const isValidVote = React.useMemo(() => {
    return (
      isCallSuccessProposalState &&
      isProposalStateActive &&
      isPositiveAvailableVotes
    );
  }, [
    isCallSuccessProposalState,
    isProposalStateActive,
    isPositiveAvailableVotes
  ]);

  /** Popups */
  const {
    popupComponent: VotePopup,
    openModal: openVotePopup,
    closeModal: closeVotePopup
  } = useSigmaAlert({
    defaultInfo: {
      title: "Governance Vote",
      subTitle: `Vote for Shogun Protocol`
    },
    children: (
      <GovCaseVoteModal
        governanceItem={governanceItem}
        handleCancelPopup={handleCancelPopup}
        onSuccessTransactions={onSuccessTransactions}
      />
    )
  });

  return (
    <div className="w-full flex-col ">
      {VotePopup}
      <div className="w-full  flex sm:flex-row flex-col ">
        <div className=" flex sm:w-[12%] w-full  items-center sm:mt-0 mt-[10px] justify-center text-[20px] font-semibold"></div>
        <div className=" sm:w-[29%] w-full flex sm:flex-row flex-col sm:justify-center justify-between items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-[30px] mt-[10px] ">
          <GovernStakingDetailItem
            className="sm:w-[50%] w-full"
            title="Agreed"
            loading={isLoadingProposal}
            isWalletConnected={true}
            contentNode={
              <div className="flex flex-col sm:items-start items-end">
                <p>{`${displayForVotesPercent} %`} </p>
                <p>{`${displayForVotes} ${TOKENS.vxSHO.name}`} </p>
              </div>
            }
          />
          <GovernStakingDetailItem
            className="sm:w-[50%] w-full sm:mt-0 mt-[10px]"
            title="Disagreed"
            loading={isLoadingProposal}
            isWalletConnected={true}
            contentNode={
              <div className="flex flex-col sm:items-start items-end">
                <p>{`${displayAgainstVotesPercent} %`} </p>
                <p>{`${displayAgainstVotes} ${TOKENS.vxSHO.name}`} </p>
              </div>
            }
          />
        </div>

        <div className=" relative sm:w-[14.5%] w-full  flex flex-col sm:justify-center justify-between  items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-[30px] mt-[10px]">
          <GovernStakingDetailItem
            className=""
            title={`Voted ${TOKENS.vxSHO.name}`}
            loading={isLoadingProposal || isLoadingTotalVXSHOSupply}
            isWalletConnected={true}
            contentNode={
              <div className="flex flex-col sm:items-start items-end">
                <p>{`${displayVXSHOSupplyPercent} %`} </p>
                <p>{`${displayTotalVotes} ${TOKENS.vxSHO.name}`} </p>
                <p className="xlg:text-[14px] lg:text-[12px] md:text-[11px] text-[10px] opacity-50">
                  {`Total: ${displayNumberFormatTotalVXSHOSupply} ${TOKENS.vxSHO.name}`}{" "}
                </p>
              </div>
            }
          />
        </div>
        <div className=" relative sm:w-[14.5%] w-full  flex flex-col sm:justify-center justify-between  items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-[30px] mt-[10px]">
          <GovernStakingDetailItem
            className=""
            title={`Available Votes`}
            loading={isLoadingAvailableVotes}
            isWalletConnected={true}
            contentNode={
              <div className="flex flex-col sm:items-start items-end">
                <p>{`${displayAvailableVotes} ${TOKENS.vxSHO.name}`} </p>
              </div>
            }
          />
        </div>

        <div className=" sm:px-[10px] px-0  sm:w-[30%] w-full flex flex-col  sm:justify-center justify-between items-center whitespace-pre-wrap xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] ">
          <SigmaButton
            className={`${
              isValidVote
                ? "main_bg text-white"
                : "border-[1px] border-[#ffffff50] cursor-not-allowed opacity-50"
            } relative overflow-hidden sm:mt-[15px] mt-[10px]    w-full lg:h-[50px] md:h-[45px] sm:h-[40px] h-[40px] md:text-[18px] sm:text-[16px] text-[14px] md:font-semibold sm:font-medium font-normal rounded-md flex justify-center items-center   `}
            onClick={onClickVote}
          >
            <p>Vote</p>
            {isLoadingProposalState && (
              <LoadingModal
                className="absolute z-10 main_bg w-full h-full"
                loadingClassName="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px]"
                message={null}
              />
            )}
          </SigmaButton>
        </div>
      </div>
      <div className="w-full  flex sm:flex-row flex-col mt-[10px]">
        <div className="flex sm:w-[12%] w-full  items-center sm:mt-0 mt-[10px] justify-center text-[20px] font-semibold"></div>
        <div className="flex flex-col sm:w-[88%] w-full shogun_bg-primary rounded-md mr-[10px] sm:p-[30px] p-[10px]">
          <p className="text-[14px] font-semibold sm:mb-[20px] mb-[10px]">
            Proposal Details
          </p>
          <p className="whitespace-pre-wrap text-[14px]">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default GovListRowDetails;
