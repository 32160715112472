import React from "react";
import { Pagination } from "@mui/material";

import VoteListHeaderRow from "./VoteListHeaderRow";
import VoteListRow from "./VoteListRow";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import { LoadingModal } from "../../../../../components/Loading";
import VoteConnector from "../../../../../context/Vote/VoteConnector";

const VoteList = () => {
  /** States */
  const PER_PAGE = 5;

  let {
    pageCount,
    poolVotes,
    isLoadingPoolVotes,
    fetchPoolVotes,
    setPoolVotesLoading,

    currentPage,
    jump,
    maxPage
  } = VoteConnector.useContainer();

  /** LifeCycle */

  useSigmaDidMount(() => {
    fetchPoolVotes(currentPage, PER_PAGE);
  }, [currentPage]);

  /** Events */

  const handlePagination = (e, p) => {
    // setPage(p);
    setPoolVotesLoading();
    jump(p);
  };

  const onClickVoteAll = () => {
    // if (!isValidUserPoolVotes) return;
    // const filteredVoteItems = voteListItems
    //   .map((voteListItem) => {
    //     return {
    //       ...voteListItem,
    //       vxSIGAmount: voteListItem.inputValue
    //     };
    //   })
    //   .filter((voteItem) => {
    //     return isBNPositive(BN(voteItem.vxSIGAmount));
    //   });
    // const isVoteItemsEmpty = filteredVoteItems.length <= 0;
    // if (isVoteItemsEmpty) {
    //   enqueueSnackbar({
    //     text: `No vxSIG added. Please add vxSIG and try again.`,
    //     type: TOAST_TYPE.INFO
    //   });
    //   return;
    // }
    // const leftVoteCount = 10 - convertedUserVotesCount;
    // const isValidVoteAll = leftVoteCount >= filteredVoteItems.length;
    // if (!isValidVoteAll) {
    //   enqueueSnackbar({
    //     text: `You can vote for up to 10 pools.`,
    //     type: TOAST_TYPE.INFO
    //   });
    //   return;
    // }
    // SigmaAlert({
    //   children: (
    //     <SigmaVoterAddPoolVoteModal
    //       voteItems={filteredVoteItems}
    //       enqueueSnackbar={enqueueSnackbar}
    //       sigmaST={sigmaST}
    //       onSuccessTransactions={onSuccessVoteListTransactions}
    //     />
    //   ),
    //   defaultInfo: {
    //     title: "Vote All",
    //     subTitle: `Confirm the voting pool and amount as below.`
    //   }
    // });
  };

  return (
    <div className="w-full flex flex-col">
      <div className="min-h-[30px]" />
      <VoteListHeaderRow
        className={"mb-[15px]"}
        sortable={false}
        onClickVoteAll={onClickVoteAll}
        isValidUserPoolVotes={"isValidUserPoolVotes"}
      />
      {isLoadingPoolVotes ? (
        <LoadingModal
          className="w-full h-[60px] my-[100px]"
          message={"Loading Pool Votes Data"}
        />
      ) : (
        poolVotes.map((voteListItem, index) => {
          return (
            <VoteListRow
              voteListItem={voteListItem}
              key={`voteitem-${index}`}
            />
          );
        })
      )}

      <Pagination
        className=" flex justify-center items-center"
        count={pageCount}
        size="large"
        page={currentPage}
        onChange={handlePagination}
        sx={{
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "#aa00ff",
            color: "black"
          },
          "& .MuiPaginationItem-root": {
            color: "white"
          }
        }}
      />

      <div className="min-h-[100px]" />
    </div>
  );
};

export default VoteList;
