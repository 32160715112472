import React from "react";
import { motion } from "framer-motion";
import { TOKENS } from "../../web3/constants";
import SigmaButton from "../../components/Animation/SigmaButton";
import {
  ErrorValue,
  WalletConnection
} from "../../components/SigmaValueDisplay";
import { Loading, LoadingModal } from "../../components/Loading";
import { STAGE_TYPES } from "../Lockdrop";
import useTokenSaleConstants from "../../web3/hooks/TokenSale/ReadOnly/useTokenSaleConstants";
import Connector from "../../context/WalletConnector/Connector";
import useSigmaDidMount from "../../hooks/useSigmaDidMount";
import { BN, isBNPositive } from "../../web3/utils/AKBN";
import {
  SigmaFormatAlert,
  ALERT_TYPE,
  useSigmaAlert
} from "../../components/SigmaAlert";
import TokenSaleDepositOneNumberInput from "./Modal/TokenSaleDepositOneNumberInput";
import TokenSaleRedeemModal from "./Modal/TokenSaleRedeemModal";
import TokenSaleWithdrawOneNumberInput from "./Modal/TokenSaleWithdrawOneNumberInput";

const MintValueDisplay = ({ stageType, MOTION_VARIANTS }) => {
  const isStageOneActive = stageType === STAGE_TYPES.UNLIMITED.key;
  const { address, isWalletConnected, connectWallet } =
    Connector.useContainer();

  const {
    totalDepositBN,
    isLoadingTotalDeposit,
    isPositiveTotalDeposit,
    displayTotalDeposit,
    fetchTotalDeposit,

    totalSHOSupplyBN,
    isLoadingTotalSHOSupply,
    isPositiveTotalSHOSupply,

    fetchTotalSHOSupply,

    isLoadingDepositInfo,
    displayDeposit,
    isTokensClaimed,
    isValidIsTokensClaimed,
    withdrewAtPhase2,
    isValidWithdrewAtPhase2,
    fetchDepositInfo,

    isLoadingWithdrawableToken,
    displayWithdrawableToken,
    isPositiveWithdrawableToken,
    fetchWithdrawableToken,

    isSHOTokensReleased,
    isLoadingIsSHOTokensReleased,
    isCallSuccessIsSHOTokensReleased,
    isValidIsSHOTokensReleased,

    fetchIsSHOTokensReleased
  } = useTokenSaleConstants(address);

  useSigmaDidMount(() => {
    fetchDepositInfo(address);
    fetchWithdrawableToken();
    fetchIsSHOTokensReleased();
  });

  useSigmaDidMount(
    () => {
      fetchTotalDeposit();
      fetchTotalSHOSupply();
    },
    [],
    false
  );

  /** Evnets */
  const handleTrxButtons = async (type) => {
    if (stageType === STAGE_TYPES.COMINGSOON.key) return;

    if (!isWalletConnected) {
      connectWallet();
      return;
    }

    switch (type) {
      case "Withdraw":
        if (isStageOneActive) {
        } else {
          if (!isValidWithdrewAtPhase2) return;
          if (withdrewAtPhase2) {
            SigmaFormatAlert({
              alertType: ALERT_TYPE.MINT_ALEADY_WITHDRAW_KLAY
            });
            return;
          }
        }
        openWithdrawPopup();
        break;
      case "Deposit":
        openDepositPopup();

        break;
      case "Redeem":
        if (!isValidRedeem) {
          if (!isValidIsTokensClaimed) return;
          if (isTokensClaimed) {
            SigmaFormatAlert({
              alertType: ALERT_TYPE.MINT_ALEADY_REDEEMED
            });
            return;
          }

          if (!isValidIsSHOTokensReleased) return;
          if (!isSHOTokensReleased) {
            SigmaFormatAlert({
              alertType: ALERT_TYPE.MINT_NOT_RELEASE_SIG
            });
            return;
          }
          return;
        }

        openRedeemPopup();

        break;

      default:
        break;
    }
  };

  const onSuccessTransactions = async (type) => {
    fetchTotalDeposit();
    fetchDepositInfo(address);
    fetchWithdrawableToken();

    switch (type) {
      case "withdraw":
        closeWithdrawPopup();
        break;
      case "deposit":
        closeDepositPopup();
        break;
      case "redeem":
        closeRedeemPopup();
        break;

      default:
        break;
    }
  };

  const handleCancelPopup = (type) => {
    switch (type) {
      case "deposit":
        closeDepositPopup();
        break;
      case "withdraw":
        closeWithdrawPopup();
        break;
      case "redeem":
        closeRedeemPopup();
        break;

      default:
        break;
    }
  };

  /** Validation */

  const isValidRedeem = React.useMemo(
    () =>
      isPositiveWithdrawableToken &&
      !isTokensClaimed &&
      isValidIsTokensClaimed &&
      isSHOTokensReleased &&
      isValidIsSHOTokensReleased,
    [
      isPositiveWithdrawableToken,
      isTokensClaimed,
      isValidIsTokensClaimed,
      isSHOTokensReleased,
      isValidIsSHOTokensReleased
    ]
  );

  /** UI */
  const displaySHOPrice = React.useMemo(() => {
    const isValid = isPositiveTotalDeposit && isPositiveTotalSHOSupply;
    if (!isValid) return "-";
    return totalDepositBN.div(totalSHOSupplyBN).decimalPlaces(6).toString();
  }, [
    isPositiveTotalDeposit,
    totalDepositBN,
    isPositiveTotalSHOSupply,
    totalSHOSupplyBN
  ]);

  /** Components */
  let button = (
    <div
      className={`sm:h-[60px] h-[50px] flex justify-between mt-[12px] ${
        !isStageOneActive && "opacity-30"
      }`}
    >
      <SigmaButton
        className={`${
          isStageOneActive ? "" : `cursor-not-allowed opacity-30`
        } w-[47%] flex justify-center items-center rounded-md border-[1px] border-[#ffffff50] sm:text-[18px]  text-[16px] font-medium  `}
        onClick={() => {
          handleTrxButtons("Withdraw");
        }}
      >
        Withdraw
      </SigmaButton>
      <SigmaButton
        className={` ${
          isStageOneActive ? "" : "cursor-not-allowed opacity-30"
        } w-[47%] flex justify-center items-center rounded-md main_bg text-black sm:text-[18px]  text-[16px] font-medium `}
        onClick={() => {
          handleTrxButtons("Deposit");
        }}
      >
        Deposit
      </SigmaButton>
    </div>
  );

  switch (stageType) {
    case STAGE_TYPES.COMINGSOON.key:
      break;
    case STAGE_TYPES.UNLIMITED.key:
      break;
    case STAGE_TYPES.WITHDRAWONLY.key:
      button = (
        <SigmaButton
          className={`${
            "isValidWithdrawAtPhase2"
              ? "AKBtnEffect"
              : "cursor-not-allowed opacity-30"
          } relative sm:h-[60px] h-[50px] mt-[12px]  w-full flex justify-center items-center rounded-md main_bg text-black sm:text-[18px]  text-[16px] font-medium `}
          onClick={() => {
            handleTrxButtons("Withdraw");
          }}
        >
          {false && (
            <LoadingModal
              className="absolute w-full h-full z-10 main_bg"
              loadingClassName="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px]"
              message={null}
            />
          )}
          <p>Withdraw</p>
        </SigmaButton>
      );
      break;
    case STAGE_TYPES.REDEEM.key:
      button = (
        <SigmaButton
          className={`${
            isValidRedeem ? "" : "cursor-not-allowed opacity-30"
          } relative sm:h-[60px] h-[50px] mt-[12px]  w-full flex justify-center items-center rounded-md main_bg text-black sm:text-[18px]  text-[16px] font-medium `}
          onClick={() => {
            handleTrxButtons("Redeem");
          }}
        >
          {(false || false || false) && (
            <LoadingModal
              className="absolute w-full h-full z-10 main_bg"
              loadingClassName="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px]"
              message={null}
            />
          )}

          <p>Redeem</p>
        </SigmaButton>
      );
      break;

    default:
      break;
  }

  /** Popup */
  const {
    popupComponent: DepositPopup,
    openModal: openDepositPopup,
    closeModal: closeDepositPopup
  } = useSigmaAlert({
    defaultInfo: {
      title: "Deposit",
      subTitle: "Deposit USDC to Shogun Mint to get SHO"
    },
    children: (
      <TokenSaleDepositOneNumberInput
        onSuccessTransactions={onSuccessTransactions}
        handleCancelPopup={handleCancelPopup}
      />
    ),
    closeOnDocumentClick: false
  });

  const {
    popupComponent: WithdrawPopup,
    openModal: openWithdrawPopup,
    closeModal: closeWithdrawPopup
  } = useSigmaAlert({
    defaultInfo: {
      title: "Withdraw",
      subTitle: `Withdraw USDC from Shogun Mint. ${
        isStageOneActive
          ? "You can freely deposit and withdraw in this stage."
          : "You can only withdraw once in this stage."
      }`
    },

    children: (
      <TokenSaleWithdrawOneNumberInput
        onSuccessTransactions={onSuccessTransactions}
        handleCancelPopup={handleCancelPopup}
        isStageOneActive={isStageOneActive}
      />
    ),
    closeOnDocumentClick: false
  });

  const {
    popupComponent: RedeemPopup,
    openModal: openRedeemPopup,
    closeModal: closeRedeemPopup
  } = useSigmaAlert({
    defaultInfo: {
      title: "Redeem",
      subTitle: `Please confirm for redeem ${TOKENS.SHO.name} token.`
    },
    children: (
      <TokenSaleRedeemModal
        onSuccessTransactions={onSuccessTransactions}
        handleCancelPopup={handleCancelPopup}
      />
    ),
    closeOnDocumentClick: false
  });

  return (
    <motion.div variants={MOTION_VARIANTS}>
      {DepositPopup}
      {WithdrawPopup}
      {RedeemPopup}
      <div className="flex flex-col sm:mt-[30px] mt-[25px] ">
        {[
          {
            title: `Total ${TOKENS.USDC.name} in the Vault`,
            value: displayTotalDeposit,
            token: TOKENS.USDC,
            loading: isLoadingTotalDeposit,
            error: false
          },
          {
            title: "Current Token Price",
            value: displaySHOPrice,
            token: TOKENS.SHO,
            displaySHOPriceByBucks: displaySHOPrice,
            unit: ` $`,
            loading: isLoadingTotalDeposit || isLoadingTotalSHOSupply,
            error: false
          },
          {
            title: "Your Contribution",
            value: displayDeposit,
            token: TOKENS.USDC,
            loading: isLoadingDepositInfo,
            error: false
          },
          {
            title: "Redeemable Amount",
            value: displayWithdrawableToken,
            token: TOKENS.SHO,
            loading: isLoadingWithdrawableToken,
            error: false
          }
        ].map((MV, index) => (
          <MintValueItem
            sigmaST={"sigmaST"}
            title={MV.title}
            value={MV.value}
            displaySHOPriceByBucks={MV.displaySHOPriceByBucks}
            token={MV.token}
            key={`FSValueDisplay-${index}`}
            index={index}
            unit={MV.unit}
            loading={MV.loading}
            error={MV.error}
          />
        ))}
      </div>
      {button}
    </motion.div>
  );
};

const MintValueItem = ({
  sigmaST,
  title,
  value,
  loading,
  error,
  token,
  unit,
  index,
  displaySHOPriceByBucks
}) => {
  return (
    <div className="sm:h-[80px] h-[70px] w-full sm:px-[30px] px-[15px] flex justify-between items-center mb-[8px] shogun_bg-secondary rounded-md">
      <p className="opacity-50 sm:text-[16px] text-[14px]">{title}</p>
      <div className="flex items-center">
        <div className="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px] sm:mr-[12px] mr-[9px]  ">
          <img src={token.logo} alt="bg" />
        </div>
        <div className="relative flex justify-end items-center">
          {!sigmaST && index !== 0 && index !== 1 ? (
            <WalletConnection />
          ) : loading ? (
            <Loading />
          ) : error ? (
            <ErrorValue />
          ) : (
            <p
              className={`sm:font-semibold font-medium sm:text-[20px] text-[18px] whitespace-pre-line flex text-right`}
            >
              {`${value}${unit || ""}`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default MintValueDisplay;
