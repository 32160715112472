import React from "react";
import useStrapiData from "../../../../../../hooks/Fetch/useStrapiData";
import useStrapiMeta from "../../../../../../hooks/Fetch/useStrapiMeta";
import { URL, PROJECT_ID, API_TOKEN } from "../../../../../../utils/constants";
import { GET } from "../../../../../../utils/fetch";
import { ContractCallState } from "../../../../../../web3/constants";
import useConstantProperties from "../../../../../../web3/hooks/useConstantProperties";

export default function useGovList() {
  const apiName = "governances";
  const PER_PAGE = 5;
  /** governances */
  const [res, setRes] = React.useState(null);
  const [apiState, setApiState] = React.useState(ContractCallState.NEW);
  const { isCallSuccess, isLoading } = useConstantProperties(apiState);

  const fetch = async (currentPage) => {
    let requestInfo = {
      url: `${URL.BASEURL}/api/${PROJECT_ID}-${apiName}`,
      params: {
        "pagination[page]": currentPage,
        "pagination[pageSize]": PER_PAGE,
        sort: "createdAt:desc",
        "filters[isActive][$eq]": true
      },
      headers: {
        Authorization: `Bearer ${API_TOKEN.READ}`
      }
    };

    try {
      setApiState(ContractCallState.FETCHING);
      const governancesRes = await GET(requestInfo);
      setApiState(ContractCallState.SUCCESS);
      setRes(governancesRes);
    } catch (error) {
      console.error(error.message);
      setApiState(ContractCallState.ERROR);
      setRes(null);
    }
  };

  /** Paginations */
  const { page, pageCount, pageSize, pageTotal } = useStrapiMeta(res);

  /** data */
  const { data, isValidData } = useStrapiData(res, isCallSuccess);

  const attributes = React.useMemo(() => {
    if (!isValidData) return [];
    return data
  }, [isValidData, data]);

  const setLoading = () => {
    setApiState(ContractCallState.FETCHING);
  };

  return {
    /** Paginations */
    page,
    pageCount,
    pageSize,
    pageTotal,

    data,
    isValidData,
    governances: attributes,
    isLoadingGovernances: isLoading,
    isCallSuccessGovernances: isCallSuccess,
    fetchGovernances: fetch,
    setGovernancesLoading: setLoading
  };
}
