import React from "react";
import { debounce } from "lodash";

import { LoadingModal } from "../../../../../components/Loading";
import {
  FlexDualButton,
  UnitValueDisplay
} from "../../../../../components/SigmaValueDisplay";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import AgreeIcon from "../../../../../assets/images/global_agree.png";
import DisagreeIcon from "../../../../../assets/images/global_disagree.png";
import Connector from "../../../../../context/WalletConnector/Connector";
import useSGConstants from "../../../../../web3/hooks/ShogunGovern/ReadOnly/useSGConstants";
import { TOKENS } from "../../../../../web3/constants";
import useSGCastVote from "../../../../../web3/hooks/ShogunGovern/useSGCastVote";
import SigmaButton from "../../../../../components/Animation/SigmaButton";

const GovCaseVoteModal = ({
  governanceItem,
  handleCancelPopup,
  onSuccessTransactions
}) => {
  const { proposalId } = governanceItem;
  const [isSupport, setIsSupport] = React.useState(undefined);
  const isValidSupport = React.useMemo(() => {
    return typeof isSupport === "boolean";
  }, [isSupport]);
  const { address, isWalletConnected, connectWallet } =
    Connector.useContainer();
  const {
    isLoadingAvailableVotes,
    isPositiveAvailableVotes,
    displayAvailableVotes,
    fetchAvailableVotes,

    isLoadingReceipt,
    hasVoted,
    isValidHasVoted,
    fetchReceipt
  } = useSGConstants(address);

  const {
    /** Tx Fee */
    isCallSuccessCastVoteTxFee,
    isLoadingCastVoteTxFee,
    fetchCastVoteTxFee,
    displayCastVoteTxFee,
    setCastVoteTxFeeLoading,

    /** Tx */
    isLoadingCastVoteTx,
    fetchCastVoteTx,

    /** Helpers */
    isValidCastVoteTx
  } = useSGCastVote();

  /** LifeCycle */

  useSigmaDidMount(() => {
    fetchAvailableVotes(proposalId);
    fetchReceipt(proposalId);
  });

  /** Debounce */
  React.useEffect(() => {
    if (
      isWalletConnected &&
      isValidSupport &&
      isPositiveAvailableVotes &&
      isValidHasVoted &&
      !hasVoted
    ) {
      if (!isLoadingCastVoteTxFee) setCastVoteTxFeeLoading();
      onDebounce(proposalId, isSupport);
    }
  }, [
    isWalletConnected,
    isValidSupport,
    isPositiveAvailableVotes,
    isSupport,
    isValidHasVoted,
    hasVoted
  ]);

  const onDebounce = React.useCallback(
    debounce((proposalId, isSupport) => {
      fetchCastVoteTxFee(proposalId, isSupport);
    }, 1000),
    [address]
  );

  /** Event */

  const handleAgree = (isSuppoert) => {
    setIsSupport(isSuppoert);
  };

  const onClickVote = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidTransaction) return;

    fetchCastVoteTx(proposalId, isSupport).then(() => {
      handleCancelPopup("castVote");
      if (typeof onSuccessTransactions === "function")
        onSuccessTransactions("castVote");
    });
  };

  /** Validations */

  const isValidTransaction = React.useMemo(() => {
    return (
      isValidSupport &&
      isPositiveAvailableVotes &&
      isValidCastVoteTx &&
      isValidHasVoted &&
      !hasVoted
    );
  }, [
    isValidSupport,
    isPositiveAvailableVotes,
    isValidCastVoteTx,
    isValidHasVoted,
    hasVoted
  ]);

  return (
    <div className={` flex flex-col items-center relative w-full`}>
      {isLoadingCastVoteTx && (
        <LoadingModal
          className="absolute w-full h-full z-10 outer_bg "
          loadingClassName={"sm:w-[80px] w-[50px] sm:h-[80px] h-[50px]"}
          messageClassName={
            "sm:text-[20px] text-[16px] md:mt-[20px] mt-[10px] text-white"
          }
        />
      )}

      <div className="flex flex-col sm:mt-[40px] mt-[30px] w-[80%]">
        <div className="flex justify-between">
          <SigmaButton
            className={`${
              isSupport === true ? "shadow-md shadow-[#33ff99]" : "opacity-50"
            } w-[47%] h-[45px] flex justify-center items-center AKBtnEffect main_c main_bd border-[1px] rounded-md `}
            onClick={() => {
              handleAgree(true);
            }}
          >
            {" "}
            <div className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px] w-[16px] h-[16px]  flex mr-[5px]">
              <img src={AgreeIcon} alt="agree" />
            </div>
            <p className="lg:text-[20px] sm:text-[16px] text-[14px] lg:font-medium font-normal">
              Agree
            </p>
          </SigmaButton>
          <SigmaButton
            className={`${
              isSupport === false ? "shadow-md shadow-[#FF4444]" : "opacity-50"
            } w-[47%] h-[45px] flex justify-center items-center AKBtnEffect  border-[1px] rounded-md border-[#FF4444] text-[#FF4444] `}
            onClick={() => {
              handleAgree(false);
            }}
          >
            <div className="lg:w-[24px] lg:h-[24px] sm:w-[20px] sm:h-[20px] w-[16px] h-[16px]  flex mr-[5px]">
              <img src={DisagreeIcon} alt="agree" />
            </div>
            <p className="lg:text-[20px] sm:text-[16px] text-[14px] lg:font-medium font-normal">
              Disagree
            </p>
          </SigmaButton>
        </div>

        <UnitValueDisplay
          title="Vote Status"
          value={!hasVoted && isValidHasVoted ? "Enable" : "Voted Already"}
          unit={""}
          className="mt-[10px] text-white"
          loading={isLoadingReceipt}
        />

        <UnitValueDisplay
          title="AvailableVotes"
          value={displayAvailableVotes}
          unit={TOKENS.vxSHO.name}
          className="text-white"
          loading={isLoadingAvailableVotes}
        />

        <UnitValueDisplay
          title="Tx Fee"
          value={displayCastVoteTxFee}
          unit={TOKENS.MATIC.name}
          className=" text-white"
          loading={isLoadingCastVoteTxFee}
          error={false}
        />
      </div>

      <SigmaButton className="flex justify-between w-full sm:h-[50px] h-[40px] mt-[15px] sm:text-[18px]  text-[16px] font-medium">
        <div
          className={`${
            isValidTransaction ? "" : "cursor-not-allowed opacity-50"
          } w-full overflow-hidden  h-full    flex justify-center items-center rounded-md main_bg text-black `}
          onClick={onClickVote}
        >
          <p>Vote</p>
        </div>
      </SigmaButton>
    </div>
  );
};

export default GovCaseVoteModal;
