import React from "react";
import { URL, PROJECT_ID, API_TOKEN } from "../../../../../utils/constants";
import { GET } from "../../../../../utils/fetch";
import { ContractCallState } from "../../../../../web3/constants";
import useConstantProperties from "../../../../../web3/hooks/useConstantProperties";

export default function usePoolVotes() {
  /** poolVotes */
  const [res, setRes] = React.useState(null);
  const [apiState, setApiState] = React.useState(ContractCallState.NEW);
  const { isCallSuccess, isLoading } = useConstantProperties(apiState);

  const fetch = async (currentPage, PER_PAGE) => {
    let requestInfo = {
      url: `${URL.BASEURL}/api/${PROJECT_ID}-pool-votes`,
      params: {
        "pagination[page]": currentPage,
        "pagination[pageSize]": PER_PAGE,
        sort: "order"
      },
      headers: {
        Authorization: `Bearer ${API_TOKEN.READ}`
      }
    };

    try {
      setApiState(ContractCallState.FETCHING);
      const poolVotesRes = await GET(requestInfo);
      setApiState(ContractCallState.SUCCESS);
      setRes(poolVotesRes);
    } catch (error) {
      console.error(error.message);
      setApiState(ContractCallState.ERROR);
      setRes(null);
    }
  };

  /** Meta */
  const pagination = React.useMemo(() => {
    return res?.meta?.pagination ? res?.meta?.pagination : undefined;
  }, [res]);

  const pageAttributes = React.useMemo(() => {
    return {
      page: pagination?.page ? pagination?.page : 0,
      pageCount: pagination?.pageCount ? pagination?.pageCount : 0,

      pageSize: pagination?.pageSize ? pagination?.pageSize : 0,
      total: pagination?.total ? pagination?.total : 0
    };
  }, [pagination]);

  /** data */
  const data = React.useMemo(() => {
    return res?.data ? res?.data : undefined;
  }, [res]);

  const isValidData = React.useMemo(() => {
    return Array.isArray(data) && isCallSuccess;
  }, [data, isCallSuccess]);

  const attributes = React.useMemo(() => {
    if (!isValidData) return [];
    return data
  }, [isValidData, data]);

  const setLoading = () => {
    setApiState(ContractCallState.FETCHING);
  };

  return {
    ...pageAttributes,
    data,
    isValidData,
    poolVotes: attributes,
    isLoadingPoolVotes: isLoading,
    isCallSuccessPoolVotes: isCallSuccess,
    fetchPoolVotes: fetch,
    setPoolVotesLoading: setLoading
  };
}
