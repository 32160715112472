const VoteListHeaderRow = ({
  className,
  sortable,
  onClickVoteAll,
  isValidUserPoolVotes
}) => {
  return (
    <div className={`${className} w-full sm:flex hidden px-[30px] `}>
      <div className="w-[16%]" />
      <div className="w-[12%] ">
        {/* <VoteListHeaderItem title="APR" sortable={sortable} /> */}
      </div>
      <div className=" w-[16%] ">
        <VoteListHeaderItem title="Current Votes" sortable={sortable} />
      </div>
      <div className="w-[24%] " />
      <div className=" w-[32%] px-[5px]">
        {/* <div
          className={`${
            isValidUserPoolVotes ? "" : "opacity-50 cursor-not-allowed"
          } border-[1px] border-[#ffffff50] w-full lg:h-[50px] md:h-[45px] sm:h-[40px] h-[40px] md:text-[18px] sm:text-[16px] text-[14px] md:font-semibold sm:font-medium font-normal rounded-md flex justify-center items-center   `}
          onClick={onClickVoteAll}
        >
          Vote All
        </div> */}
      </div>
    </div>
  );
};

const VoteListHeaderItem = ({ title, handleSort, sortable }) => {
  return (
    <div
      className={`flex w-full items-end justify-center h-full ${
        sortable && "transition-all hover:opacity-70 cursor-pointer"
      }  `}
      onClick={() => {
        if (!sortable) return;
        handleSort(title);
      }}
    >
      <p className="xlg:text-[14px] lg:text-[13px] text-[12px] opacity-50 xlg:mr-[5px] lg:mr-[4px] mr-[3px]">
        {title}
      </p>
    </div>
  );
};

export default VoteListHeaderRow;
