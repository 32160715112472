import React from "react";
import Connector from "../../../../context/WalletConnector/Connector";
import useSigmaDidMount from "../../../../hooks/useSigmaDidMount";
import UserVoteList from "./UserVoteList";
import VoteList from "./VoteList";

const Pool = ({ onSuccessTransactions }) => {
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();

  useSigmaDidMount(() => {});

  return (
    <section className="w-full flex flex-col items-center">
      <UserVoteList />
      {/* <UserVoteList voteItems={USER_VOTE_LIST} /> */}
      <VoteList />
    </section>
  );
};

export default Pool;
