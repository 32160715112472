// import { useSnackbar } from "notistack";
import React, { useContext } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

// import { SigmaAlert } from "../../components/SigmaAlert";
import LoadingLayout from "../../components/Loading/LoadingLayout";
import { URL } from "../../utils/constants";
import Disclaimer from "./Disclaimer";
import ComingSoon from "./Stages/ComingSoon";
import Unlimited from "./Stages/Unlimited";
import { STAGE_TYPES } from "../Lockdrop";
import WithdrawOnly from "./Stages/WithdrawOnly";
import Redeem from "./Stages/Redeem";
import { useSigmaAlert } from "../../components/SigmaAlert";
import useSigmaDidMount from "../../hooks/useSigmaDidMount";
import { TOKENS } from "../../web3/constants";

dayjs.extend(utc);

const STAGE_ONE_START_UNIX = 1673017200;
const STAGE_TWO_START_UNIX = 1673535600;
const STAGE_TWO_END_UNIX = 1673622000;
const STAGE_ONE_DURATION = STAGE_TWO_START_UNIX - STAGE_ONE_START_UNIX;

const Mint = () => {
  const [currentUnixTime, setCurrentUnixTime] = React.useState(0);

  /** Life Cycle */
  React.useEffect(() => {
    const interval = setInterval(intervalCallback, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    openDisclaimer();
  }, []);

  const intervalCallback = React.useCallback(() => {
    const currentUnixTime = dayjs().utc().unix(); // utc current time unit
    setCurrentUnixTime(currentUnixTime);
  }, [setInterval]);

  /** Events */
  const openDisclaimer = () => {
    const isDcRead = localStorage.getItem("isDcRead");
    if (!isDcRead) {
      openDisclaimerModal();
    }
  };
  /** Popup */
  const onSuccessConfirm = () => {
    closeDisclaimerModal();
  };
  const {
    popupComponent: DisclaimerComponent,
    openModal: openDisclaimerModal,
    closeModal: closeDisclaimerModal
  } = useSigmaAlert({
    children: <Disclaimer onSuccessConfirm={onSuccessConfirm} />,
    closeOnDocumentClick: false
  });

  /** UI */

  const stageType = React.useMemo(() => {
    if (currentUnixTime === 0) return null;

    if (currentUnixTime < STAGE_ONE_START_UNIX) {
      return STAGE_TYPES.COMINGSOON.key;
    } else if (
      STAGE_ONE_START_UNIX <= currentUnixTime &&
      currentUnixTime < STAGE_TWO_START_UNIX
    ) {
      return STAGE_TYPES.UNLIMITED.key;
    } else if (
      STAGE_TWO_START_UNIX <= currentUnixTime &&
      currentUnixTime < STAGE_TWO_END_UNIX
    ) {
      return STAGE_TYPES.WITHDRAWONLY.key;
    } else {
      return STAGE_TYPES.REDEEM.key;
    }
  }, [currentUnixTime]);

  const step = React.useMemo(() => {
    if (
      stageType === STAGE_TYPES.COMINGSOON.key ||
      stageType === STAGE_TYPES.REDEEM.key
    )
      return;
    if (stageType === STAGE_TYPES.WITHDRAWONLY.key) return 7;
    for (let i = 0; i < 6; i++) {
      const unitTime =
        STAGE_ONE_START_UNIX + STAGE_ONE_DURATION * 0.1666666 * (i + 1);
      if (currentUnixTime < unitTime) return i + 1;
    }
  }, [currentUnixTime]);

  const LEFT_UNIT_TIME = React.useMemo(
    () =>
      stageType === STAGE_TYPES.COMINGSOON.key
        ? STAGE_ONE_START_UNIX
        : stageType === STAGE_TYPES.UNLIMITED.key
        ? STAGE_TWO_START_UNIX
        : STAGE_TWO_END_UNIX,
    [stageType]
  );

  const leftSeconds = React.useMemo(
    () =>
      currentUnixTime > 0
        ? Math.floor((LEFT_UNIT_TIME - currentUnixTime) % 60)
        : 0,
    [currentUnixTime]
  );
  const leftMinutes = React.useMemo(
    () =>
      currentUnixTime > 0
        ? Math.floor(((LEFT_UNIT_TIME - currentUnixTime) % (60 * 60)) / 60)
        : 0,
    [leftSeconds]
  );

  const leftHours = React.useMemo(
    () =>
      currentUnixTime > 0
        ? Math.floor(
            ((LEFT_UNIT_TIME - currentUnixTime) % (60 * 60 * 24)) / (60 * 60)
          )
        : 0,
    [leftMinutes]
  );

  const leftDays = React.useMemo(() => {
    if (
      stageType === STAGE_TYPES.UNLIMITED.key ||
      stageType === STAGE_TYPES.COMINGSOON.key
    ) {
      return currentUnixTime > 0
        ? Math.floor((LEFT_UNIT_TIME - currentUnixTime) / (60 * 60 * 24))
        : 0;
    } else {
      return 0;
    }
  }, [leftMinutes]);

  const description = React.useMemo(() => {
    switch (stageType) {
      case STAGE_TYPES.COMINGSOON.key:
        return {
          stage: "Time left to start stage 1",
          day: "",
          des: ``
        };
      case STAGE_TYPES.UNLIMITED.key:
        return {
          stage: "Time left in this stage",
          day: "Day 1 - 6",
          des: `During this period, there are no limit on deposits or withdrawals of ${TOKENS.USDC.name}.
          Once this period ends, you will no longer be able to deposit  ${TOKENS.USDC.name}.`
        };

      case STAGE_TYPES.WITHDRAWONLY.key:
        return {
          stage: "Time left in this stage",
          day: "Day 7",
          des: `During this period, you can only withdraw  ${TOKENS.USDC.name}.
          The amount you can withdraw decreases every hour by 1/24th (4.2%)
          Only 1 withdrawal trnasaction can be made during this period.`
        };
      case STAGE_TYPES.REDEEM.key:
        return {
          stage: "",
          day: "Redeem Your Tokens",
          des: `When the SHO-USDC pool is live on Meshswap you can redeem 
          your SHO tokens here.`
        };

      default:
        return {
          stage: "",
          day: "",
          des: ``
        };
    }
  }, [stageType]);

  const StageComponent = React.useMemo(() => {
    switch (stageType) {
      case STAGE_TYPES.COMINGSOON.key:
        return (
          <ComingSoon
            stageType={stageType}
            description={description}
            leftDays={leftDays}
            leftHours={leftHours}
            leftMinutes={leftMinutes}
            leftSeconds={leftSeconds}
          />
        );

      case STAGE_TYPES.UNLIMITED.key:
        return (
          <Unlimited
            stageType={stageType}
            description={description}
            leftDays={leftDays}
            leftHours={leftHours}
            leftMinutes={leftMinutes}
            leftSeconds={leftSeconds}
            step={step}
          />
        );

      case STAGE_TYPES.WITHDRAWONLY.key:
        return (
          <WithdrawOnly
            stageType={stageType}
            description={description}
            leftDays={leftDays}
            leftHours={leftHours}
            leftMinutes={leftMinutes}
            leftSeconds={leftSeconds}
            step={step}
          />
        );

      case STAGE_TYPES.REDEEM.key:
        return <Redeem stageType={stageType} description={description} />;

      default:
        return null;
    }
  }, [stageType, leftSeconds]);

  return (
    <section className="w-full  flex flex-col items-center relative sigma_inner ">
      {DisclaimerComponent}
      <LoadingLayout />
      <div className="h-[60px]" />
      {StageComponent}

      <div className="w-full min-h-[75px]" />

      {/* 

        

        <div className="h-[75px]" />
      </div> */}
    </section>
  );
};

export default Mint;
