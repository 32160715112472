import React from "react";
import { debounce } from "lodash";

import { UnitValueDisplay } from "../../../components/SigmaValueDisplay";
import { Loading, LoadingModal } from "../../../components/Loading";
import { TOKENS } from "../../../web3/constants";

import USDCTokenContract from "../../../web3/contracts/USDCTokenContract";
import TokenSaleContract from "../../../web3/contracts/TokenSaleContract";
import SigmaButton from "../../../components/Animation/SigmaButton";
import Connector from "../../../context/WalletConnector/Connector";

import useSigmaCurrencyInput from "../../../hooks/TextField/useSigmaCurrencyInput";

import useSigmaDidMount from "../../../hooks/useSigmaDidMount";
import useTSDepositUSDC from "../../../web3/hooks/TokenSale/useTSDepositUSDC";
import useUSDC from "../../../web3/hooks/ERC20/useUSDC";

const TokenSaleDepositOneNumberInput = ({
  onSuccessTransactions,
  handleCancelPopup
}) => {
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();

  const {
    balance: USDCBalance,
    isPositiveBalance,
    fetchBalance: fetchUSDCBalance,
    displayBalance: displayUSDCBalance,
    isLoadingBalance: isLoadingUSDCBalance,

    /** isApproved */
    isApproved,
    isLoadingAllowance,
    isAllowanceCallSuccess,
    fetchAllowance,

    /** Approve */
    isLoading: isLoadingApproveTrx,
    fetchApprove
  } = useUSDC(USDCTokenContract);

  const {
    inputComponent,
    maxComponent,
    isPositive: isInputPositive,
    weiValue,
    mweiValue,
    isBiggerThanBalance
  } = useSigmaCurrencyInput({
    name: TOKENS.USDC.name,
    placeholder: `${TOKENS.USDC.name} to Deposit`,
    balance: USDCBalance
  });

  const {
    /** Tx Fee */
    isCallSuccessDepositUSDCTxFee,
    isLoadingDepositUSDCTxFee,
    fetchDepositUSDCTxFee,
    displayDepositUSDCTxFee,
    setDepositUSDCTxFeeLoading,

    /** Tx */
    isLoadingDepositUSDCTx,
    fetchDepositUSDCTx
  } = useTSDepositUSDC();

  /** Initiallizers */

  useSigmaDidMount(() => {
    fetchUSDCBalance(address);
    fetchAllowance(address, TokenSaleContract.address);
  });

  /** Debounce */
  React.useEffect(() => {
    if (
      isInputPositive &&
      isWalletConnected &&
      isApproved &&
      !isBiggerThanBalance
    ) {
      if (!isLoadingDepositUSDCTxFee) setDepositUSDCTxFeeLoading(true);
      onDebounceDepositUSDCGas(mweiValue);
    }
  }, [
    isInputPositive,
    mweiValue,
    isWalletConnected,
    isApproved,
    isBiggerThanBalance
  ]);

  const onDebounceDepositUSDCGas = React.useCallback(
    debounce((amount) => {
      fetchDepositUSDCTxFee(amount);
    }, 1000),
    []
  );

  /** Event */

  const onClickApprove = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }

    if (!isValidApproveTransaction) return;
    fetchApprove(TokenSaleContract.address).then(() => {
      fetchAllowance(address, TokenSaleContract.address);
    });
  };

  const onClickDeposit = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidDepositTransaction) return;
    fetchDepositUSDCTx(mweiValue).then(() => {
      if (typeof onSuccessTransactions === "function")
        onSuccessTransactions("deposit");
    });
  };

  /** Validations */

  const isValidApproveTransaction = React.useMemo(() => {
    return !isLoadingApproveTrx && isAllowanceCallSuccess;
  }, [isLoadingApproveTrx, isAllowanceCallSuccess]);

  const isValidDepositTransaction = React.useMemo(() => {
    return (
      isInputPositive &&
      !isBiggerThanBalance &&
      isPositiveBalance &&
      isApproved &&
      isCallSuccessDepositUSDCTxFee &&
      !isLoadingDepositUSDCTx &&
      isWalletConnected
    );
  }, [
    isInputPositive,
    isBiggerThanBalance,
    isPositiveBalance,
    isApproved,
    isCallSuccessDepositUSDCTxFee,
    isLoadingDepositUSDCTx,
    isWalletConnected
  ]);

  /** UI */

  return (
    <div
      className={` w-full flex flex-col items-center relative ${
        isLoadingDepositUSDCTx && "overflow-hidden"
      }`}
    >
      {isLoadingDepositUSDCTx && (
        <LoadingModal
          className="absolute w-full h-full z-10 outer_bg "
          loadingClassName={"sm:w-[80px] w-[50px] sm:h-[80px] h-[50px]"}
          messageClassName={
            "sm:text-[20px] text-[16px] md:mt-[20px] mt-[10px] text-white"
          }
        />
      )}
      <div className="min-h-[35px]" />
      <UnitValueDisplay
        title="Balance"
        value={displayUSDCBalance}
        unit={TOKENS.USDC.name}
        className="mb-[10px] text-white"
        loading={isLoadingUSDCBalance}
        error={false}
      />
      <div className="flex w-full transition-all hover:scale-105">
        {inputComponent}
        {maxComponent}

        <LPTokenDisplay
          farmItem={TOKENS.USDC}
          className="sm:min-w-[33%] min-w-[28%] border-[#ffffff20] border-[1px] rounded-r-md"
        />
      </div>

      <UnitValueDisplay
        title="Tx Fee"
        value={displayDepositUSDCTxFee}
        unit={TOKENS.MATIC.name}
        className="mt-[5px] text-white"
        loading={isLoadingDepositUSDCTxFee}
        error={false}
      />

      <div className="h-[20px]" />

      <div className="flex justify-between w-full sm:h-[60px] h-[50px]">
        <SigmaButton
          className="SADefault_button h-full flex justify-center items-center text-white border-[1px] border-[#ffffff50] sm:text-[18px] text-[14px] font-semibold  rounded-md AKBtnEffect"
          onClick={() => {
            handleCancelPopup("deposit");
          }}
        >
          Cancel
        </SigmaButton>
        {isApproved ? (
          <SigmaButton
            className={`relative SADefault_button 
          ${isValidDepositTransaction ? "" : "opacity-50 cursor-not-allowed"}
            h-full flex justify-center items-center main_bg text-black sm:text-[18px] text-[14px] font-semibold rounded-md transition-all hover:scale-105  `}
            onClick={onClickDeposit}
          >
            Deposit
          </SigmaButton>
        ) : (
          <SigmaButton
            className={`relative overflow-hidden ${
              isValidApproveTransaction ? "" : "opacity-50 cursor-not-allowed"
            }
    
                SADefault_button sm:min-h-[60px] min-h-[48px] flex justify-center items-center main_bg text-black sm:text-[18px] text-[14px] font-semibold rounded-md   `}
            onClick={onClickApprove}
          >
            <p>Approve</p>
            {(isLoadingApproveTrx || isLoadingAllowance) && (
              <LoadingModal
                className="absolute z-10 main_bg w-full h-full"
                loadingClassName="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px]"
                message={null}
              />
            )}
          </SigmaButton>
        )}
      </div>
    </div>
  );
};

const LPTokenDisplay = ({ farmItem, className }) => {
  const { name, logo } = farmItem;
  return (
    <div className={`${className} flex justify-center`}>
      <div className="flex items-center text-white">
        {logo && (
          <div className="sm:w-[26px] sm:h-[26px] w-[16px] h-[16px] sm:mr-[12px] mr-[4px]">
            <img src={logo} alt="icon" />
          </div>
        )}
        <p className="sm:text-[18px] text-[12px] main_c">{name}</p>
      </div>
    </div>
  );
};

export default TokenSaleDepositOneNumberInput;
