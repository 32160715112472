import React from "react";
import { Pagination } from "@mui/material";
import UserVoteListHeaderRow from "./UserVoteListHeaderRow";

import { LoadingModal } from "../../../../../components/Loading";
import Connector from "../../../../../context/WalletConnector/Connector";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import useUserPoolVotePagination from "../../../../../hooks/useUserPoolVotePagination";
import UserVoteListRow from "./UserVoteListRow";
import VoteConnector from "../../../../../context/Vote/VoteConnector";

const UserVoteList = () => {
  /** States */
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();
  const {
    userPoolVotes,
    isLoadingUserPoolVotes,
    isCallSuccessUserPoolVotes,
    isValidUserPoolVotes,
    displayUserPoolVotes,
    fetchUserPoolVotes
  } = VoteConnector.useContainer();

  useSigmaDidMount(() => {
    fetchUserPoolVotes();
  });

  React.useEffect(() => {}, [displayUserPoolVotes]);

  // let [page, setPage] = React.useState(1);
  const PER_PAGE = 5;
  const count = Math.ceil(displayUserPoolVotes.length / PER_PAGE);
  const { currentData, jump, maxPage, currentPage } = useUserPoolVotePagination(
    displayUserPoolVotes,
    PER_PAGE
  );
  const [userVoteListItems, setUserVoteListItems] = React.useState([]);

  /** LifeCycle */
  // React.useEffect(() => {
  //   if (!didMount) return;
  //   if (!sigmaST) return;
  //   if (voteItems.length <= 0) return;
  //   if (currentData.length <= 0) return;
  //   setUserVoteListItems(
  //     currentData.map((currentData) => {
  //       return {
  //         ...currentData,
  //         inputValue: "0"
  //       };
  //     })
  //   );
  // }, [currentData, didMount, sigmaST, voteItems]);

  /** Events */
  const handlePagination = (e, p) => {
    jump(p);
  };

  // const handleUserVoteListItemInputValue = ({ index, inputValue }) => {
  //   let _userVoteListItems = [...userVoteListItems];
  //   _userVoteListItems[index] = {
  //     ..._userVoteListItems[index],
  //     inputValue
  //   };
  //   setUserVoteListItems(_userVoteListItems);
  // };

  // const onSuccessUserVoteListTransactions = (type, _trxHash) => {
  //   if (
  //     onSuccessVoteTransactions &&
  //     typeof onSuccessVoteTransactions === "function"
  //   )
  //     onSuccessVoteTransactions(type, _trxHash);
  // };

  const onClickWithdrawAll = () => {
    // SigmaAlert({
    //   children: (
    //     <SigmaVoterDeletePoolVoteModal
    //       voteItems={userVoteListItems}
    //       enqueueSnackbar={enqueueSnackbar}
    //       sigmaST={sigmaST}
    //       onSuccessTransactions={onSuccessUserVoteListTransactions}
    //     />
    //   ),
    //   defaultInfo: {
    //     title: "Withdraw All",
    //     subTitle: `Confirm the voting pool and amount as below.`
    //   }
    // });
  };

  /** Display */

  const isDisplayPagination = React.useMemo(() => {
    return maxPage > 1;
  }, [maxPage]);

  return (
    <div className="w-full relative  border-[#ffffff50] border-[1px] rounded-md  mt-[20px] overflow-hidden">
      {isLoadingUserPoolVotes && (
        <LoadingModal
          className="absolute w-full h-full z-10 inner_bg"
          loadingClassName={"sm:w-[60px] w-[40px] sm:h-[60px] h-[40px]"}
          messageClassName={"sm:text-[16px] text-[12px] md:mt-[10px] mt-[5px]"}
          message="Loading your vote dashboard"
        />
      )}
      {currentData.length > 0 ? (
        <div className="w-full flex flex-col p-[10px]">
          <p className="sm:text-[16px] text-[14px] my-[30px] ml-[3%]">{`Your Vote Dashboard`}</p>
          <UserVoteListHeaderRow
            className={"mb-[15px]"}
            sortable={false}
            onClickWithdrawAll={onClickWithdrawAll}
          />
          {currentData.map((userVoteListItem, index) => {
            return (
              <UserVoteListRow
                userVoteListItem={userVoteListItem}
                key={`voteitem-${index}`}
              />
            );
          })}
          <Pagination
            className=" flex justify-center items-center"
            count={count}
            size="large"
            page={currentPage}
            onChange={handlePagination}
            sx={{
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#aa00ff",
                color: "black"
              },
              "& .MuiPaginationItem-root": {
                color: "white"
              }
            }}
          />
        </div>
      ) : (
        <div className="p-[50px] sm:text-[16px] text-[12px] flex justify-center items-center">
          Vote first.
        </div>
      )}
    </div>
  );
};

export default UserVoteList;
