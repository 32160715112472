import React from "react";
import { Tabs, Tab } from "@mui/material";
import FarmListRowValue from "../Farm/FarmList/FarmListRowValue";
import useSigmaDidMount from "../../hooks/useSigmaDidMount";
import Connector from "../../context/WalletConnector/Connector";
import useSVConstants from "../../web3/hooks/Vote/ReadOnly/useSVConstants";
import useERC20 from "../../web3/hooks/ERC20/useERC20";
import vxSHOTokenContract from "../../web3/contracts/vxSHOTokenContract";
import useERC20Balance from "../../web3/hooks/ERC20/useERC20Balance";
import { useParams, useNavigate } from "react-router-dom";
import VoteContentDisplay from "./VoteContentDisplay";
import VoteConnector from "../../context/Vote/VoteConnector";
import { TOKENS } from "../../web3/constants";
const TAB_INFO = {
  pool: 0,
  governance: 1
  // gov: 2
};

const Vote = (props) => {
  /** States */
  const { voteContent } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = React.useState(TAB_INFO[voteContent]);
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();
  let {
    availableVotes,
    availableVotesBN,
    isLoadingAvailableVotes,
    isCallSuccessAvailableVotes,
    isValidAvailableVotes,
    isPositiveAvailableVotes,
    numberedAvailableVotes,

    displayAvailableVotes,
    fetchAvailableVotes
  } = VoteConnector.useContainer();

  const {
    balance: vxSHOBalance,
    balanceBN: vxSHOBalanceBN,
    isPositiveBalance: isPositiveVXSHOBalance,
    fetchBalance: fetchVXSHOBalance,
    isLoadingBalance: isLoadingVXSHOBalance
  } = useERC20Balance(vxSHOTokenContract);
  /** Life Cycle */

  useSigmaDidMount(() => {
    fetchAvailableVotes();
    fetchVXSHOBalance(address);
    // fetchUserPoolVotes({
    //   isConnectionCheck: true,
    //   address: sigmaST.address
    // });
  });

  React.useEffect(() => {
    const _tabIndex = TAB_INFO[voteContent];
    const isValidTabIndex = _tabIndex >= 0 && _tabIndex <= 2;
    if (!isValidTabIndex) {
      navigate("/vote/pool");
      return;
    }
    if (_tabIndex === tabIndex) return;
    if (isValidTabIndex) setTabIndex(_tabIndex);
  }, [voteContent]);

  const handleTabIndexChange = (event, newValue) => {
    // let directionPath =
    //   newValue === 1 ? "agenda" : newValue === 2 ? "governance" : "pool";
    let directionPath = newValue === 1 ? "governance" : "pool";
    navigate(`/vote/${directionPath}`);
  };

  /** Validations */

  /** UI */

  const displayIntegerVXSHOBalance = React.useMemo(() => {
    if (!isPositiveVXSHOBalance) return "-";
    return vxSHOBalanceBN.decimalPlaces(0).toString();
  });

  return (
    <section className="w-full flex flex-col items-center ">
      <div className="w-[90%] flex flex-col mt-[60px]">
        {/* Remaining Vote Weight */}
        {voteContent === "pool" && (
          <div className=" w-full flex flex-col sm:p-[30px] p-[20px] sm:mt-[60px] mt-[30px]  shogun_bg-secondary rounded-md ">
            <p className="sm:text-[14px] text-[12px] opacity-50">
              Remaining Vote Weight
            </p>
            <div className="flex items-center">
              {tabIndex === 0 && (
                <>
                  <FarmListRowValue
                    isWalletConnected={isWalletConnected}
                    loading={isLoadingAvailableVotes}
                    valueNode={
                      <p className="sm:text-[32px] text-[24px] font-semibold">
                        {displayAvailableVotes}
                      </p>
                    }
                  />

                  <p className="mx-[5px] sm:text-[20px] text-[14px]">/</p>
                </>
              )}
              <FarmListRowValue
                isWalletConnected={isWalletConnected}
                loading={isLoadingVXSHOBalance}
                valueNode={
                  <p className="sm:text-[32px] text-[24px] ">
                    {displayIntegerVXSHOBalance}
                  </p>
                }
              />

              <div className="sm:text-[16px] text-[12px] ml-[10px] h-[60%] flex flex-col justify-end">
                {TOKENS.vxSHO.name}
              </div>
            </div>
          </div>
        )}
        <Tabs
          className="mt-[60px]"
          value={tabIndex}
          onChange={handleTabIndexChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#aa00ff",
              top: 0
            },
            "& .MuiTab-textColorPrimary": {
              color: "#ffffff",
              fontSize: "16px",
              opacity: 50,
              fontWeight: 500,
              fontFamily: "Poppins"
            },

            "& .MuiTab-textColorPrimary.Mui-selected": {
              color: "#aa00ff",
              fontWeight: 700
            }
          }}
        >
          <Tab className="cursor-pointer" label="Pool" id="simple-tab-1" />
          {/* <Tab label="Agenda" id="simple-tab-2" /> */}
          <Tab label="Governance" id="simple-tab-2" />
        </Tabs>
        <VoteContentDisplay />
      </div>
    </section>
  );
};

export default Vote;
