import React from "react";
import { URL, PROJECT_ID, API_TOKEN } from "../../../../../utils/constants";
import { GET } from "../../../../../utils/fetch";
import { ContractCallState } from "../../../../../web3/constants";
import useConstantProperties from "../../../../../web3/hooks/useConstantProperties";

export default function useUserPoolVote() {
  /** poolVotes */
  const [res, setRes] = React.useState(null);
  const [apiState, setApiState] = React.useState(ContractCallState.NEW);
  const { isCallSuccess, isLoading } = useConstantProperties(apiState);

  const fetch = async (poolAddress) => {
    let requestInfo = {
      url: `${URL.BASEURL}/api/${PROJECT_ID}-pool-votes`,
      params: {
        "filters[address][$eq]": poolAddress
      },
      headers: {
        Authorization: `Bearer ${API_TOKEN.READ}`
      }
    };

    try {
      setApiState(ContractCallState.FETCHING);
      const poolVotesRes = await GET(requestInfo);
      setApiState(ContractCallState.SUCCESS);
      setRes(poolVotesRes);
    } catch (error) {
      console.error(error.message);
      setApiState(ContractCallState.ERROR);
      setRes(null);
    }
  };

  /** data */
  const data = React.useMemo(() => {
    return res?.data ? res?.data : undefined;
  }, [res]);

  const isValidData = React.useMemo(() => {
    return Array.isArray(data) && isCallSuccess && data.length === 1;
  }, [data, isCallSuccess]);

  const attribute = React.useMemo(() => {
    if (!isValidData) return [];
    return data[0].attributes;
  }, [isValidData, data]);

  const setLoading = () => {
    setApiState(ContractCallState.FETCHING);
  };

  return {
    data,
    isValidData,
    userPoolVote: attribute,
    isLoadingUserPoolVote: isLoading,
    isCallSuccessUserPoolVote: isCallSuccess,
    fetchUserPoolVote: fetch,
    setUserPoolVoteLoading: setLoading
  };
}
