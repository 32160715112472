import React from "react";

import ConvertInputDisplay from "./ConvertInputDisplay";
import SHOMESHStakingListRow from "../Farm/FarmList/SHOMESHStakingListRow";
import FarmListHeaderRow from "../Farm/FarmList/FarmListHeaderRow";

const Convert = () => {
  /** Life Cycle */

  return (
    <section className="w-full  flex flex-col items-center">
      <div className="lg:mt-[60px] md:mt-[40px] mt-[20px] w-[90%]">
        {/* Top Display */}

        {/* Convert  */}
        <ConvertInputDisplay />
        <div className="sm:min-h-0 min-h-[20px]" />

        <div className="w-full flex flex-col sm:mt-[50px] mt-0">
          <FarmListHeaderRow className={"mb-[15px]"} />
          <SHOMESHStakingListRow />
        </div>
      </div>
      <div className=" h-[50px]" />
    </section>
  );
};

export default Convert;
