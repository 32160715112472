import React from "react";

import { UnitValueDisplay } from "../../../components/SigmaValueDisplay";
import { LoadingModal } from "../../../components/Loading";
import { TOKENS } from "../../../web3/constants";
import SigmaButton from "../../../components/Animation/SigmaButton";
import Connector from "../../../context/WalletConnector/Connector";
import useSigmaDidMount from "../../../hooks/useSigmaDidMount";
import useTSRedeemSHO from "../../../web3/hooks/TokenSale/useTSRedeemSHO";
import useTokenSaleConstants from "../../../web3/hooks/TokenSale/ReadOnly/useTokenSaleConstants";

const TokenSaleRedeemModal = ({ onSuccessTransactions, handleCancelPopup }) => {
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();

  const {
    isLoadingDepositInfo,
    displayDeposit,
    isTokensClaimed,
    isValidIsTokensClaimed,
    withdrewAtPhase2,
    isValidWithdrewAtPhase2,
    fetchDepositInfo,

    isLoadingWithdrawableToken,
    displayWithdrawableToken,
    isPositiveWithdrawableToken,
    fetchWithdrawableToken
  } = useTokenSaleConstants(address);

  const {
    /** Tx Fee */
    isCallSuccessRedeemSHOTxFee,
    isLoadingRedeemSHOTxFee,
    fetchRedeemSHOTxFee,
    displayRedeemSHOTxFee,

    /** Tx */
    isLoadingRedeemSHOTx,
    fetchRedeemSHOTx
  } = useTSRedeemSHO();

  /** Initiallizers */

  useSigmaDidMount(() => {
    fetchDepositInfo(address);
    fetchWithdrawableToken();
    fetchRedeemSHOTxFee();
  });

  /** Event */

  const onClickRedeem = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidRedeemTransaction) return;
    fetchRedeemSHOTx().then(() => {
      if (typeof onSuccessTransactions === "function")
        onSuccessTransactions("redeem");
    });
  };

  /** Validations */

  const isValidRedeemTransaction = React.useMemo(() => {
    return (
      isPositiveWithdrawableToken &&
      isCallSuccessRedeemSHOTxFee &&
      !isLoadingRedeemSHOTx &&
      isWalletConnected
    );
  }, [
    isPositiveWithdrawableToken,
    isCallSuccessRedeemSHOTxFee,
    isLoadingRedeemSHOTx,
    isWalletConnected
  ]);

  /** UI */

  return (
    <div
      className={` w-full flex flex-col items-center relative ${
        isLoadingRedeemSHOTx && "overflow-hidden"
      }`}
    >
      {isLoadingRedeemSHOTx && (
        <LoadingModal
          className="absolute w-full h-full z-10 outer_bg "
          loadingClassName={"sm:w-[80px] w-[50px] sm:h-[80px] h-[50px]"}
          messageClassName={
            "sm:text-[20px] text-[16px] md:mt-[20px] mt-[10px] text-white"
          }
        />
      )}
      <div className="min-h-[35px]" />
      <UnitValueDisplay
        title="Your Deposit USDC"
        value={displayDeposit}
        unit={TOKENS.USDC.name}
        className="mb-[10px] text-white"
        loading={isLoadingDepositInfo}
        error={false}
      />
      <UnitValueDisplay
        title="Redeemable SHO"
        value={displayWithdrawableToken}
        unit={TOKENS.SHO.name}
        className="mb-[10px] text-white"
        loading={isLoadingWithdrawableToken}
        error={false}
      />

      <UnitValueDisplay
        title="Tx Fee"
        value={displayRedeemSHOTxFee}
        unit={TOKENS.MATIC.name}
        className="mt-[5px] text-white"
        loading={isLoadingRedeemSHOTxFee}
        error={false}
      />

      <div className="h-[20px]" />

      <div className="flex justify-between w-full sm:h-[60px] h-[50px]">
        <SigmaButton
          className="SADefault_button h-full flex justify-center items-center text-white border-[1px] border-[#ffffff50] sm:text-[18px] text-[14px] font-semibold  rounded-md AKBtnEffect"
          onClick={() => {
            handleCancelPopup("redeem");
          }}
        >
          Cancel
        </SigmaButton>
        <SigmaButton
          className={`relative SADefault_button 
          ${isValidRedeemTransaction ? "" : "opacity-50 cursor-not-allowed"}
            h-full flex justify-center items-center main_bg text-black sm:text-[18px] text-[14px] font-semibold rounded-md transition-all hover:scale-105  `}
          onClick={onClickRedeem}
        >
          Redeem
        </SigmaButton>
      </div>
    </div>
  );
};

export default TokenSaleRedeemModal;
