import React from "react";
import AKRadioAgreement from "../../components/AKRadioAgreement";

import ShogunLogo from "../../assets/images/global_logo-text.png";

const Disclaimer = ({ onSuccessConfirm }) => {
  const { isRadioSelected, radioComponent } = AKRadioAgreement({
    className: "mb-[23px]",
    message: `I read and accept disclaimer`
  });

  const onClickConfirm = () => {
    if (!isRadioSelected) return;
    window.localStorage.setItem("isDcRead", JSON.stringify(true));
    onSuccessConfirm();
  };

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col sm:px-[60px] items-center ">
        <div className="w-[80px] h-[17.51px]">
          <img src={ShogunLogo} alt="logo" />
        </div>
        <p className="sm:mt-[25px] mt-[20px] sm:text-[32px] text-[25px] opacity-100 text-white">
          Disclaimer
        </p>

        <div className="w-full  flex md:h-fit sm:max-h-[400px] max-h-[300px] overflow-y-scroll text-white">
          <p className=" break-all mt-[13px] sm:text-[16px] text-[14px] opacity-70 whitespace-pre-wrap">{`PLEASE READ THE ENTIRETY OF THIS "DISCLAIMER" SECTION CAREFULLY. NOTHING HEREIN CONSTITUTES LEGAL, FINANCIAL, BUSINESS OR TAX ADVICE AND YOU SHOULD CONSULT YOUR OWN LEGAL, FINANCIAL, TAX OR OTHER PROFESSIONAL ADVISOR(S) BEFORE ENGAGING IN ANY ACTIVITY IN CONNECTION HEREWITH. ANY OF THE SHOGUN PROTOCOL CONTRIBUTORS (THE SHOGUN TEAM) WHO HAVE WORKED ON SHOGUN PROTOCOL OR PROJECT TO DEVELOP SHOGUN PROTOCOL IN ANY WAY WHATSOEVER, ANY DISTRIBUTOR/VENDOR OF $SHO TOKENS (THE DISTRIBUTOR), NOR ANY SERVICE PROVIDER SHALL BE LIABLE FOR ANY KIND OF DIRECT OR INDIRECT DAMAGE OR LOSS WHATSOEVER WHICH YOU MAY SUFFER IN CONNECTION WITH ACCESSING THE PAPER, DECK OR OTHER MATERIAL RELATING TO $SHO OR PUBLISHED IN CONNECTION WITH SHOGUN PROTOCOL (COLLECTIVELY, THE TOKEN DOCUMENTATION ) AVAILABLE ON THE WEBSITES AT HTTPS://SHOGUNPROTOCOL.COM, TOGETHER WITH ANY SOCIAL MEDIA ACCOUNTS OPERATED BY THE SHOGUN TEAM OR IN CONNECTION WITH SHOGUN PROTOCOL (COLLECTIVELY, THE WEBSITES , INCLUDING ANY SUB-DOMAINS THEREON).

Project purpose: You agree that you are acquiring $SHO to participate in Shogun Protocol and to obtain services on the ecosystem thereon. The Distributor is acting solely as an arms' length third party in relation to the $SHO distribution, and not in the capacity as a financial advisor or fiduciary of any person with regard to the distribution of $SHO.

`}</p>
        </div>
        <div className="flex flex-col pt-[]"></div>
      </div>
      <div
        className=" w-full shogun_bg-primary flex flex-col items-center sm:px-[60px] py-[23px] px-[20px]
        "
      >
        {radioComponent}
        <div
          onClick={onClickConfirm}
          className={`${
            isRadioSelected
              ? "cursor-pointer transition-all hover:scale-105"
              : "cursor-not-allowed opacity-50"
          } w-full sm:h-[60px] h-[50px] flex justify-center items-center text-black sm:text-[18px] text-[16px] font-semibold  main_bg rounded-md`}
        >
          Confirm
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
