import React from "react";
import { BN, isBNPositive, convertToETH } from "../../../../../web3/utils/AKBN";

const GovListHeaderRow = ({ className }) => {
  return (
    <div className={`${className} w-full sm:flex hidden`}>
      <div className="w-[12%]">
        <AgendaListHeaderItem title="Proposal Id." />
      </div>
      <div className="w-[32%] ">
        <AgendaListHeaderItem title="Title" />
      </div>
      <div className=" w-[10%] ">
        <AgendaListHeaderItem title="Status" />
      </div>
      <div className="w-[16%] ">
        <AgendaListHeaderItem title="Voting Period" />
      </div>
      <div className=" w-[30%] px-[5px] ">
        {/* <div
          className={`${
            false ? "" : "cursor-not-allowed opacity-50"
          }  main_bg text-black w-full lg:h-[50px] md:h-[45px] sm:h-[40px] h-[40px] md:text-[18px] sm:text-[16px] text-[14px] md:font-semibold sm:font-medium font-normal rounded-md flex justify-center items-center   `}
          onClick={onClickSubmit}
        >
          Initiate proposal
        </div> */}
      </div>
    </div>
  );
};

const AgendaListHeaderItem = ({ title }) => {
  return (
    <div className={`flex w-full items-end justify-center h-full   `}>
      <p className="xlg:text-[14px] lg:text-[13px] text-[12px] opacity-50 xlg:mr-[5px] lg:mr-[4px] mr-[3px]">
        {title}
      </p>
    </div>
  );
};

export default GovListHeaderRow;
