import React from "react";
import { LoadingModal } from "../../../../components/Loading";
import GovListHeaderRow from "./GovList/GovListHeaderRow";
import GovListRow from "./GovList/GovListRow";
import { BN, isBNPositive, convertToETH } from "../../../../web3/utils/AKBN";

import useSigmaDidMount from "../../../../hooks/useSigmaDidMount";
import { TOKENS, AlchemyProvider } from "../../../../web3/constants";
import VoteConnector from "../../../../context/Vote/VoteConnector";
import usePagination from "../../../../hooks/usePagination";
import useGovBlock from "./GovList/hooks/useGovBlockInfo";

const Guide = `1. ${TOKENS.vxSHO.name} is used to represent vote rights and can be redeemed via ${TOKENS.xSHO.name} staking, and if any of ${TOKENS.xSHO.name} is unstacked, all proposal vote participation of the user will be invalidated.
2. ${TOKENS.vxSHO.name} can only be voted in integer units.
3. When proposing a proposal, the amount of ${TOKENS.SHO.name} that must be held in the wallet is 170,000 ${TOKENS.SHO.name}. The proposal registration is made by the team, and the proposal proposed by a user may be rejected due to technical issues regarding its proposal.
4. The amount of ${TOKENS.SHO.name} required when proposing proposals is 0 and may be changed in the future. All ${TOKENS.SHO.name} used in the proposal will be burned.
5. The main content of the proposal must be at least 500 characters and must be written in English only.
6. Voting rights (${TOKENS.vxSHO.name}) to participate in each proposal are available for 3 days until the voting is completed, and if any ${TOKENS.xSHO.name} unstaking happens,all proposal vote participation of the user will be invalidated.
7. If the voting is invalidated through ${TOKENS.xSHO.name} unstaking after the vote, re-voting is not possible.
8. Quorum is calculated based on the last vote or invalidation transaction(caused by ${TOKENS.xSHO.name} unstaking) before the proposal deadline.
9. The criteria for passing a vote are as follows. If the total number of voters is above the minimum participation standard(8% of total vxSHO), and more than a majority of the total voters agree.
10. The criteria for rejecting a vote are as follows. In the case of participating less than the minimum number of voting rights until the end of the voting period (3 days) In a case where a majority of all voters do not approve at the end of the voting period
11. After the vote is over, if the proposal is passed, the proposal will be executed at least after a two-day time lock period. If this is not just a simple parameter change, but a structural change that requires a contract upgrade, it may take more than two days. When the proposal is executed successfully, the community will be notified.`;

const Gov = ({
  convertedVXSIGBalanceBN,
  onSuccessTransactions: onSuccessVoteTransactions
}) => {
  /** States */
  const {
    pageCount,

    governances,
    isLoadingGovernances,
    fetchGovernances,
    setGovernancesLoading,

    fetchBlock
  } = VoteConnector.useContainer();

  const { currentPage, component: GovPagination } = usePagination(
    pageCount,
    () => {
      setGovernancesLoading();
    }
  );

  /** Life Cycle */
  useSigmaDidMount(
    () => {
      fetchGovernances(currentPage);
      fetchBlock();
    },
    [currentPage],
    false
  );

  return (
    <div className="w-full flex flex-col ">
      <div className="flex flex-col w-full items-start mt-[10px]">
        <p className="opacity-40 font-semibold text-[14px] my-[5px]">
          Governance Guide
        </p>
        <div className="whitespace-pre-wrap text-[12px] opacity-50  text-left leading-[23px] px-[15px]">
          {Guide}
        </div>
      </div>

      <div className="min-h-[30px]" />
      <GovListHeaderRow className={"mb-[15px]"} />
      {isLoadingGovernances && (
        <LoadingModal
          className="w-full "
          message={"Loading Governance Data"}
          loadingClassName="w-[60px] h-[60px]"
        />
      )}
      {governances.map((governanceItem, index) => {
        return (
          <GovListRow
            itemIndex={index}
            governanceItem={governanceItem}
            key={`governanceItem-${index}`}
          />
        );
      })}
      {GovPagination}

      <div className="min-h-[100px]" />
    </div>
  );
};

export default Gov;
