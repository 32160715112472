import React from "react";
import {
  AlchemyProvider,
  ContractCallState
} from "../../../../../../web3/constants";
import useConstantProperties from "../../../../../../web3/hooks/useConstantProperties";
import useNumericTokenConstants from "../../../../../../web3/hooks/useNumericTokenConstants";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export default function useGovBlock() {
  /** block */
  const [block, setBlock] = React.useState(null);
  const [blockState, setBlockState] = React.useState(ContractCallState.NEW);
  const { isCallSuccess, isLoading } = useConstantProperties(blockState);

  const fetch = async () => {
    try {
      setBlockState(ContractCallState.FETCHING);
      let block = await AlchemyProvider.getBlockNumber().then((blockNumber) => {
        return AlchemyProvider.getBlock(blockNumber);
      });
      setBlockState(ContractCallState.SUCCESS);
      setBlock(block);
    } catch (error) {
      console.error(error.message);
      setBlockState(ContractCallState.ERROR);
      setBlock(null);
    }
  };

  /** blockNumber */

  const blockNumber = React.useMemo(() => {
    return block?.number ? block?.number : undefined;
  }, [block]);

  const { bn: blockNumberBN, isValid: isValidBlockNumber } =
    useNumericTokenConstants(blockNumber, isCallSuccess);

  /** timestamp */
  const timestamp = React.useMemo(() => {
    return block?.timestamp ? block?.timestamp : undefined;
  }, [block]);

  const { bn: timestampBN, isValid: isValidTimestamp } =
    useNumericTokenConstants(timestamp, isCallSuccess);

  return {
    block,
    isLoadingBlock: isLoading,
    isCallSuccessBlock: isCallSuccess,

    blockNumber,
    blockNumberBN,
    isValidBlockNumber,

    timestamp,
    timestampBN,
    isValidTimestamp,

    fetchBlock: fetch
  };
}
