import React from "react";
import { debounce } from "lodash";

import { UnitValueDisplay } from "../../../components/SigmaValueDisplay";
import { Loading, LoadingModal } from "../../../components/Loading";
import { TOKENS } from "../../../web3/constants";

import SigmaButton from "../../../components/Animation/SigmaButton";
import Connector from "../../../context/WalletConnector/Connector";

import useSigmaCurrencyInput from "../../../hooks/TextField/useSigmaCurrencyInput";
import useSigmaDidMount from "../../../hooks/useSigmaDidMount";
import useTSWithdrawUSDC from "../../../web3/hooks/TokenSale/useTSWithdrawUSDC";
import useTokenSaleConstants from "../../../web3/hooks/TokenSale/ReadOnly/useTokenSaleConstants";

const TokenSaleWithdrawOneNumberInput = ({
  onSuccessTransactions,
  handleCancelPopup,
  isStageOneActive
}) => {
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();

  const {
    isLoadingDepositInfo,
    displayDeposit,
    fetchDepositInfo,

    withdrawableUSDC,
    isLoadingWithdrawableUSDC,
    isPositiveWithdrawableUSDC,

    displayWithdrawableUSDC,
    fetchWithdrawableUSDC
  } = useTokenSaleConstants(address);

  const {
    inputComponent,
    maxComponent,
    isPositive: isInputPositive,
    weiValue,
    mweiValue,
    isBiggerThanBalance
  } = useSigmaCurrencyInput({
    name: TOKENS.USDC.name,
    placeholder: `${TOKENS.USDC.name} to Withdraw`,
    balance: withdrawableUSDC
  });

  const {
    /** Tx Fee */
    isCallSuccessWithdrawUSDCTxFee,
    isLoadingWithdrawUSDCTxFee,
    fetchWithdrawUSDCTxFee,
    displayWithdrawUSDCTxFee,
    setWithdrawUSDCTxFeeLoading,

    /** Tx */
    isLoadingWithdrawUSDCTx,
    fetchWithdrawUSDCTx
  } = useTSWithdrawUSDC();

  /** Initiallizers */

  useSigmaDidMount(() => {
    fetchDepositInfo(address);
    fetchWithdrawableUSDC();
  });

  /** Debounce */
  React.useEffect(() => {
    if (isInputPositive && isWalletConnected && !isBiggerThanBalance) {
      if (!isLoadingWithdrawUSDCTxFee) setWithdrawUSDCTxFeeLoading(true);
      onDebounceWithdrawUSDCGas(mweiValue);
    }
  }, [isInputPositive, mweiValue, isWalletConnected, isBiggerThanBalance]);

  const onDebounceWithdrawUSDCGas = React.useCallback(
    debounce((amount) => {
      fetchWithdrawUSDCTxFee(amount);
    }, 1000),
    []
  );

  /** Event */

  const onClickWithdraw = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidWithdrawTransaction) return;
    fetchWithdrawUSDCTx(mweiValue).then(() => {
      if (typeof onSuccessTransactions === "function")
        onSuccessTransactions("withdraw");
    });
  };

  /** Validations */

  const isValidWithdrawTransaction = React.useMemo(() => {
    return (
      isInputPositive &&
      !isBiggerThanBalance &&
      isPositiveWithdrawableUSDC &&
      isCallSuccessWithdrawUSDCTxFee &&
      !isLoadingWithdrawUSDCTx &&
      isWalletConnected
    );
  }, [
    isInputPositive,
    isBiggerThanBalance,
    isPositiveWithdrawableUSDC,
    isCallSuccessWithdrawUSDCTxFee,
    isLoadingWithdrawUSDCTx,
    isWalletConnected
  ]);

  /** UI */

  return (
    <div
      className={` w-full flex flex-col items-center relative ${
        isLoadingWithdrawUSDCTx && "overflow-hidden"
      }`}
    >
      {isLoadingWithdrawUSDCTx && (
        <LoadingModal
          className="absolute w-full h-full z-10 outer_bg "
          loadingClassName={"sm:w-[80px] w-[50px] sm:h-[80px] h-[50px]"}
          messageClassName={
            "sm:text-[20px] text-[16px] md:mt-[20px] mt-[10px] text-white"
          }
        />
      )}
      <div className="min-h-[15px]" />
      <UnitValueDisplay
        title="Balance"
        value={displayDeposit}
        unit={TOKENS.USDC.name}
        className="mb-[5px] text-white"
        loading={isLoadingDepositInfo}
        error={false}
      />
      {!isStageOneActive && (
        <UnitValueDisplay
          title={`Withdrawable ${TOKENS.USDT.name}`}
          value={displayWithdrawableUSDC}
          unit={TOKENS.USDT.name}
          className=" mb-[15px] text-white"
          loading={isLoadingWithdrawableUSDC}
          error={false}
        />
      )}
      <div className="flex w-full transition-all hover:scale-105">
        {inputComponent}
        {maxComponent}

        <LPTokenDisplay
          farmItem={TOKENS.USDC}
          className="sm:min-w-[33%] min-w-[28%] border-[#ffffff20] border-[1px] rounded-r-md"
        />
      </div>

      <UnitValueDisplay
        title="Tx Fee"
        value={displayWithdrawUSDCTxFee}
        unit={TOKENS.MATIC.name}
        className="mt-[5px] text-white"
        loading={isLoadingWithdrawUSDCTxFee}
        error={false}
      />

      <div className="h-[20px]" />

      <div className="flex justify-between w-full sm:h-[60px] h-[50px]">
        <SigmaButton
          className="SADefault_button h-full flex justify-center items-center text-white border-[1px] border-[#ffffff50] sm:text-[18px] text-[14px] font-semibold  rounded-md AKBtnEffect"
          onClick={() => {
            handleCancelPopup("withdraw");
          }}
        >
          Cancel
        </SigmaButton>
        <SigmaButton
          className={`relative SADefault_button 
          ${isValidWithdrawTransaction ? "" : "opacity-50 cursor-not-allowed"}
            h-full flex justify-center items-center main_bg text-black sm:text-[18px] text-[14px] font-semibold rounded-md transition-all hover:scale-105  `}
          onClick={onClickWithdraw}
        >
          Withdraw
        </SigmaButton>
      </div>
    </div>
  );
};

const LPTokenDisplay = ({ farmItem, className }) => {
  const { name, logo } = farmItem;
  return (
    <div className={`${className} flex justify-center`}>
      <div className="flex items-center text-white">
        {logo && (
          <div className="sm:w-[26px] sm:h-[26px] w-[16px] h-[16px] sm:mr-[12px] mr-[4px]">
            <img src={logo} alt="icon" />
          </div>
        )}
        <p className="sm:text-[18px] text-[12px] main_c">{name}</p>
      </div>
    </div>
  );
};

export default TokenSaleWithdrawOneNumberInput;
