import React from "react";
import { motion } from "framer-motion";
import { useParams } from "react-router-dom";
import Pool from "./Pool";
import Gov from "./Gov";

const VoteContentDisplay = ({ onSuccessTransactions }) => {
  const { voteContent } = useParams();

  let content = <></>;
  switch (voteContent) {
    case "pool":
      content = <Pool />;
      break;
    case "agenda":
      // content = (
      //   <Agenda
      //     convertedVXSIGBalanceBN={convertedVXSIGBalanceBN}
      //     onSuccessTransactions={onSuccessTransactions}
      //   />
      // );
      break;

    case "governance":
      content = <Gov />;
      break;

    default:
      break;
  }

  return <motion.div className="">{content}</motion.div>;
};

export default VoteContentDisplay;
