import React from "react";
import { debounce } from "lodash";

import FarmListRowValue from "../../../../Farm/FarmList/FarmListRowValue";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import useSigmaCurrencyInput from "../../../../../hooks/TextField/useSigmaCurrencyInput";
import { TOKENS } from "../../../../../web3/constants";
import Connector from "../../../../../context/WalletConnector/Connector";
import useSVConstants from "../../../../../web3/hooks/Vote/ReadOnly/useSVConstants";
import SigmaButton from "../../../../../components/Animation/SigmaButton";
import { UnitValueDisplay } from "../../../../../components/SigmaValueDisplay";
import { Loading, LoadingModal } from "../../../../../components/Loading";
import VoteConnector from "../../../../../context/Vote/VoteConnector";
import useSVDeletePoolVote from "../../../../../web3/hooks/Vote/useSVDeletePoolVote";
import useUserPoolVote from "./useUserPoolVote";

const UserVoteListRow = ({ userVoteListItem }) => {
  let { address: poolAddress, vxSHOAmount } = userVoteListItem;
  let { address, isWalletConnected, connectWallet } = Connector.useContainer();

  let { fetchAvailableVotes, fetchUserPoolVotes } =
    VoteConnector.useContainer();

  const {
    inputComponent,
    maxComponent,
    initInput,
    bn: inputBN,
    isValid: isValidInput,
    stringValue,
    isValidTxInInput
  } = useSigmaCurrencyInput({
    name: TOKENS.vxSHO.name,
    placeholder: `${TOKENS.vxSHO.name} to withdraw`,
    balance: vxSHOAmount
  });

  const isInputValueInteger = React.useMemo(() => {
    return isValidInput && inputBN.isInteger();
  }, [inputBN, isValidInput]);

  /** States */

  const {
    isLoadingPoolInfo,
    displayVXSHOAmount,

    fetchPoolInfo
  } = useSVConstants(address);

  const {
    /** Tx Fee */
    isLoadingDeletePoolVoteTxFee,
    fetchDeletePoolVoteTxFee,
    displayDeletePoolVoteTxFee,
    setDeletePoolVoteTxFeeLoading,

    /** Tx */
    isLoadingDeletePoolVoteTx,
    fetchDeletePoolVoteTx,

    /** Helpers */
    isValidDeletePoolVoteTx
  } = useSVDeletePoolVote();

  const { userPoolVote, isLoadingUserPoolVote, fetchUserPoolVote } =
    useUserPoolVote();
  /** Life Cycle */

  useSigmaDidMount(() => {
    fetchPoolInfo(poolAddress);
    fetchUserPoolVote(poolAddress);
  });

  /** Debounce */
  React.useEffect(() => {
    if (isWalletConnected && isValidTxInInput && isInputValueInteger) {
      if (!isLoadingDeletePoolVoteTxFee) setDeletePoolVoteTxFeeLoading();
      onDebounce(stringValue);
    }
  }, [isValidTxInInput, stringValue, isWalletConnected, isInputValueInteger]);

  const onDebounce = React.useCallback(
    debounce((stringValue) => {
      fetchDeletePoolVoteTxFee(poolAddress, stringValue);
    }, 1000),
    [address]
  );

  const onClickWithdraw = () => {
    if (!isWalletConnected) {
      connectWallet();
      return;
    }
    if (!isValidTransaction) return;
    fetchDeletePoolVoteTx(poolAddress, stringValue).then(() => {
      fetchUserPoolVotes();
      fetchAvailableVotes();

      initInput();
    });
  };

  /** Validations */

  const isValidTransaction = React.useMemo(() => {
    return (
      isInputValueInteger &&
      isValidTxInInput &&
      isValidDeletePoolVoteTx &&
      isWalletConnected
    );
  }, [
    isInputValueInteger,
    isValidTxInInput,
    isValidDeletePoolVoteTx,
    isWalletConnected
  ]);

  /** Displays */

  return (
    <div
      className="flex flex-col w-full  p-[30px] shogun_bg-secondary mb-[15px] rounded-md transition-all hover:scale-[102%]"
      key={`UserVoteListRow-${poolAddress}`}
    >
      <div className="w-full  flex sm:flex-row flex-col">
        <div className="flex sm:w-[16%] w-full  items-center sm:mt-0 mt-[10px]">
          {isLoadingUserPoolVote ? (
            <Loading className="sm:w-[20px] w-[16px] sm:h-[20px] h-[16px]" />
          ) : (
            <p className="xlg:text-[20px] lg:text-[18px] text-[16px] xlg:font-semibold font-medium">
              {userPoolVote?.name}
            </p>
          )}
        </div>
        <div className="sm:w-[12%] w-full flex sm:justify-center justify-between items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[10px]">
          <div className="sm:hidden flex opacity-50 ">Your Votes</div>
          <FarmListRowValue
            isWalletConnected={isWalletConnected}
            loading={false}
            error={false}
            valueNode={<p> {`${vxSHOAmount} ${TOKENS.vxSHO.name}`}</p>}
          />
        </div>
        <div className=" sm:w-[16%] w-full  flex sm:justify-center justify-between items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[5px] ">
          <div className="sm:hidden flex opacity-50 ">Current votes</div>
          <FarmListRowValue
            isWalletConnected={isWalletConnected}
            loading={isLoadingPoolInfo}
            error={false}
            valueNode={<p> {`${displayVXSHOAmount} ${TOKENS.vxSHO.name}`}</p>}
          />
        </div>

        <div className="relative sm:w-[24%] w-full  flex flex-col sm:justify-center justify-between  items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[5px]">
          <div className=" flex w-full transition-all hover:scale-105 rounded overflow-hidden">
            {inputComponent}
            {maxComponent}
          </div>

          {!isInputValueInteger && (
            <p className="absolute error_c text-[12px] bottom-[-18px] w-full">
              Only integer values are allowed
            </p>
          )}
          {isInputValueInteger && isValidTxInInput && (
            <UnitValueDisplay
              title="Tx Fee"
              value={displayDeletePoolVoteTxFee}
              unit={TOKENS.MATIC.name}
              className="absolute bottom-[-18px] text-white"
              loading={isLoadingDeletePoolVoteTxFee}
              error={false}
            />
          )}
        </div>
        <div className=" sm:px-[10px] px-0  sm:w-[32%] w-full flex  sm:justify-center justify-between items-center whitespace-pre-wrap xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[25px]">
          <SigmaButton
            className={`relative ${
              isValidTransaction ? "" : "opacity-50 cursor-not-allowed"
            } main_bg text-black w-full lg:h-[50px] md:h-[45px] sm:h-[40px] h-[40px] md:text-[18px] sm:text-[16px] text-[14px] md:font-semibold sm:font-medium font-normal rounded-md flex justify-center items-center   `}
            onClick={onClickWithdraw}
          >
            <p>Withdraw</p>
            {isLoadingDeletePoolVoteTx && (
              <LoadingModal
                className="absolute z-10 main_bg w-full h-full"
                loadingClassName="sm:w-[26px] w-[23px] sm:h-[26px] h-[23px]"
                message={null}
              />
            )}
          </SigmaButton>
        </div>
      </div>
    </div>
  );
};

export default UserVoteListRow;
