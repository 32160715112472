import React from "react";
import dayjs from "dayjs";
import GovListRowDetails from "./GovListRowDetails";
import VoteConnector from "../../../../../context/Vote/VoteConnector";
import useSGConstants from "../../../../../web3/hooks/ShogunGovern/ReadOnly/useSGConstants";
import Connector from "../../../../../context/WalletConnector/Connector";
import useSigmaDidMount from "../../../../../hooks/useSigmaDidMount";
import { LoadingValueItem } from "../../../../../components/SigmaValueDisplay";
import SigmaButton from "../../../../../components/Animation/SigmaButton";

const GovListRow = ({ governanceItem }) => {
  let { id, title, proposalId } = governanceItem;

  /** States */
  const { address, isWalletConnected } = Connector.useContainer();
  const [openDetails, setOpenDetails] = React.useState(false);
  const {
    isLoadingBlock,
    blockNumber,
    isValidBlockNumber,

    timestamp,
    isValidTimestamp
  } = VoteConnector.useContainer();

  const {
    isProposalFinalized,
    isLoadingIsProposalFinalized,
    isValidIsProposalFinalized,
    fetchIsProposalFinalized,

    isCallSuccessProposalState,
    isLoadingProposalState,
    displayProposalState,
    isProposalStateActive,
    fetchProposalState,

    isLoadingProposal,
    numberedStartBlock,
    isPositiveStartBlock,

    numberedEndBlock,
    isPositiveEndBlock,

    result,
    fetchProposal
  } = useSGConstants(address);

  useSigmaDidMount(
    () => {
      fetchProposalState(proposalId);
      fetchIsProposalFinalized(proposalId);
      fetchProposal(proposalId);
    },
    [],
    false
  );

  /** Events */

  const onClickDetails = () => {
    setOpenDetails(openDetails ? false : true);
  };

  /** Validations */

  /** Displays */

  const displayState = React.useMemo(() => {
    return isValidIsProposalFinalized && isProposalFinalized
      ? result
      : displayProposalState;
  }, [isProposalFinalized, isValidIsProposalFinalized]);

  const isLoadingTime = React.useMemo(() => {
    return isLoadingBlock || isLoadingProposal;
  }, [isLoadingBlock, isLoadingProposal]);

  /** Start Timestamp Item */

  const isValidStartTimestamp = React.useMemo(() => {
    return isValidTimestamp && isValidBlockNumber && isPositiveStartBlock;
  }, [isValidTimestamp, isValidBlockNumber, isPositiveStartBlock]);

  const displayStartTime = React.useMemo(() => {
    if (!isValidStartTimestamp) return "-";
    const startBlockGap = blockNumber - numberedStartBlock;
    const startTimestamp = timestamp - startBlockGap * 2;
    return dayjs.unix(startTimestamp).format("YYYY.MM.DD HH:mm");
  }, [isValidStartTimestamp, timestamp, blockNumber, numberedStartBlock]);

  /** End Timestamp Item */

  const isValidEndTimestamp = React.useMemo(() => {
    return isValidTimestamp && isValidBlockNumber && isPositiveEndBlock;
  }, [isValidTimestamp, isValidBlockNumber, isPositiveEndBlock]);

  const displayEndTime = React.useMemo(() => {
    if (!isValidEndTimestamp) return "-";
    const endBlockGap = blockNumber - numberedEndBlock;
    const endTimestamp = timestamp - endBlockGap * 2;
    return dayjs.unix(endTimestamp).format("YYYY.MM.DD HH:mm");
  }, [isValidEndTimestamp, timestamp, blockNumber, numberedEndBlock]);

  return (
    <div
      className={` flex flex-col w-full sm:px-0 px-[30px] py-[30px] shogun_bg-secondary mb-[15px] rounded-md transition-all hover:scale-[102%]`}
      key={`GovListRow-${id}`}
    >
      <div className="w-full  flex sm:flex-row flex-col">
        <div className="flex sm:w-[12%] w-full  items-center sm:mt-0 mt-[10px] sm:justify-center justify-between xlg:text-[20px] lg:text-[18px] md:text-[16px] text-[15px] md:font-semibold font-medium">
          <div className="sm:hidden flex opacity-50 ">Proposal Id.</div>
          <p>{proposalId}</p>
        </div>
        <div className="sm:w-[32%] w-full flex sm:justify-center justify-between items-center xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[10px]">
          <div className="sm:hidden flex opacity-50 ">Title</div>
          <p className=" sm:ml-0 ml-[5px]">{title}</p>
        </div>
        <div className=" sm:w-[10%] w-full  flex sm:justify-center justify-between items-center lg:text-[16px] md:text-[14px] text-[13px] sm:mt-0 mt-[5px] ">
          <div className={`sm:hidden flex opacity-50 `}>Status</div>

          <LoadingValueItem
            loading={isLoadingProposalState || isLoadingIsProposalFinalized}
            valueNode={<p>{displayState}</p>}
            isWalletConnected={true}
          />
        </div>

        <div className="sm:w-[16%] w-full  flex  sm:justify-center justify-between  items-center lg:text-[16px] md:text-[14px] text-[13px] sm:mt-0 mt-[5px]">
          <div className="sm:hidden flex opacity-50 ">Voting Period</div>
          <div className="flex flex-col justify-center ">
            <LoadingValueItem
              loading={isLoadingTime}
              valueNode={
                <p className="lg:text-[16px] md:text-[13px] sm:text-[11px] text-[13px]">{`${displayStartTime} - ${displayEndTime}`}</p>
              }
              isWalletConnected={true}
            />
          </div>
        </div>
        <div className=" sm:px-[10px] px-0  sm:w-[30%] w-full flex  sm:justify-center justify-between items-center whitespace-pre-wrap xlg:text-[16px] lg:text-[14px] md:text-[13px] text-[12px] sm:mt-0 mt-[5px]">
          <SigmaButton
            className={`main_bg text-black w-full lg:h-[50px] md:h-[45px] sm:h-[40px] h-[40px] md:text-[18px] sm:text-[16px] text-[14px] md:font-semibold sm:font-medium font-normal rounded-md flex justify-center items-center   `}
            onClick={onClickDetails}
          >
            Datails
          </SigmaButton>
        </div>
      </div>
      {openDetails && <GovListRowDetails governanceItem={governanceItem} />}
    </div>
  );
};

export default GovListRow;
